<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {
      Sponsored: "Indicados",
      Username: "Usuário",
      Name: "Nome",
      Date: "Data",
      "E-mail": "E-mail",
      Cellphone: "Celular",
      Plan: "Plano",
      Binary: "Binário",
      Status: "Status",
      Qualified: "Qualificado",
      Unqualified: "Não Qualificado",
      Left: "Esquerdo",
      Right: "Direito",
      Pending: "Pendente",
      Approved: "Aprovado",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
      Graduation: "Graduação",
    },
    es: {
      Sponsored: "Patrocinados",
      Username: "Nombre de Usuario",
      Name: "Nombre",
      Date: "Data",
      "E-mail": "E-mail",
      Cellphone: "Celular",
      Plan: "Plano",
      Binary: "Binario",
      Status: "Status",
      Qualified: "Calificado",
      Unqualified: "No Cualificado",
      Left: "Izquierdo",
      Right: "Derecho",
      Pending: "Pendiente",
      Approved: "Aprobado",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
      Graduation: "Graduación",
    },
  },
  components: {
    Layout,
    VclList,
    Stat,
  },
  data() {
    return {
      table: {
        heade: [
          "Cliente",
          "Saldo Disponível",
          "Total Aportado",
          "Rendimentos Totais",
        ],
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },

      statData: null,
    };
  },
  mounted() {
    api
      .get("network/clients")
      .then((response) => {
        if (response.data.status == "success") {
          this.table.body = response.data.list;
          this.statData = [
            {
              icon: "bx bx-user",
              title: "Total de Clientes",
              value: response.data.total,
            },
            {
              icon: "bx bx-user",
              title: "Total Aportado",
              value: this.$options.filters.currency(
                response.data.contracts
              ),
            },
            {
              icon: "bx bx-user",
              title: "Rendimentos Totais",
              value: this.$options.filters.currency(
                response.data.profits
              ),
            },
          ];
        }
      })
      .catch((error) => {
        this.table.errored = error;
      })
      .finally(() => {
        this.table.loading = false;
        if (this.table.body == "" || this.table.body == null) {
          this.table.empty = true;
        }
      });
  },
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3>Meus Clientes</h3>
        <p></p>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-3">
        <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div v-if="table.errored">
          {{
          t(
          "We apologize, we are unable to retrieve information at this time. Please try again later."
          )
          }}
        </div>
        <div v-else-if="table.empty">{{ t("No records found.") }}</div>
        <div v-else class="table-responsive">
          <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
          <table v-else class="table table-centered table-nowrap">
            <thead class="thead-light">
              <tr>
                <th v-for="th in table.heade" :key="th">{{ t(th) }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(td, index) in table.body" :key="index">
                <td>
                  <strong>{{ td.name }}</strong><br>
                  <span class="font-size-11 text-uppercase">{{ td.email }}</span><br>
                  <span class="font-size-11 text-uppercase">{{ td.cellphone }}</span>
                </td>
                <td><span class="badge badge-soft-dark font-size-14">{{ td.balance | currency }}</span></td>
                <td><span class="badge badge-soft-dark font-size-14">{{ td.contracts | currency }}</span></td>
                <td><span class="badge badge-soft-dark font-size-14">{{ td.profits | currency }}</span></td>
                <td class="align-middle">
                  <router-link :to="'/network/clients/' + td.id" class="btn btn-light py-1 px-2">
                    <i class='bx bx-show font-size-22 align-middle'></i>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.table tbody tr td {
  padding: 0.8rem 0.75rem;
}
</style>